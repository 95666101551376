<template>
    <div>
        <div class="hb-primary hb-design-system-border-radius px-4 white--text mb-6">
            <hb-header :divider="false">
                <hb-page-header title="Design System Goals, Resources, Dependencies, &amp; General Information">
                </hb-page-header>
            </hb-header>
        </div>

        <hb-card no-title class="mt-4 design-system-overview-wrapper">

            <hb-form label="Primary Goals" multiple>
                <div><hb-icon color="success" class="mr-3" style="position:relative;top:-2px;">mdi-check</hb-icon>UI/UX excellence</div>
                <div><hb-icon color="success" class="mr-3" style="position:relative;top:-2px;">mdi-check</hb-icon>Visual uniformity</div>
                <div><hb-icon color="success" class="mr-3" style="position:relative;top:-2px;">mdi-check</hb-icon>Professional polish (think of Apple as a role model with their level of quality control and design)</div>
            </hb-form>

            <hb-form label="Secondary Goals" multiple>
                <div><hb-icon color="success" class="mr-3" style="position:relative;top:-2px;">mdi-check</hb-icon>Set up mechanism to implement visual design changes easier (only need to change one file). </div>
                <div><hb-icon color="success" class="mr-3" style="position:relative;top:-2px;">mdi-check</hb-icon>Simplify current and future maintainability.</div>
                <div><hb-icon color="success" class="mr-3" style="position:relative;top:-2px;">mdi-check</hb-icon>Shorten development time for developers by giving them a premade toolset.</div>
            </hb-form>

            <hb-form label="Note" multiple>
                <div><hb-icon color="caution" class="mr-3">mdi-alert</hb-icon>This project is a shared responsibility between designers, developers, QA, product, leadership, project managers, and scrum masters in order for it to succeed. Please hold each other accountable and educate teammates about using and adhering to the design system.</div>
                <div><hb-icon color="caution" class="mr-3">mdi-alert</hb-icon>Many of these "Aviary" design system components are heavily based in, around, and built off of Vue 2 and Vuetify 2. Many of the same props, slots, and events in Vuetify 2 can be used in our aviary design system version of that same component. For instance, the HbMenu Aviary component is based off of and expands upon Vuetify 2's VMenu component, and you can go to Vuetify 2's documentation pages for VMenu to see additional prop, slot, and event options. Therefore, it is essential to have a solid understanding and foundation in Vue 2 and Vuetify 2 in order to use these design system components.</div>
            </hb-form>

            <hb-form label="Resources" multiple>
                <div><hb-icon class="mr-3">mdi-magnify</hb-icon>Npm package 'hummingbird-aviary': <hb-link class="ml-2" color="secondary" href="https://www.npmjs.com/package/hummingbird-aviary" target="_blank">https://www.npmjs.com/package/hummingbird-aviary</hb-link></div>
                <div class="ml-9">(add "hummingbird-aviary": "^0.1.0" to package.json dependencies) - npm package status is in beta and may not be fully functional for use for external applications</div>
                <div><hb-icon class="mr-3">mdi-magnify</hb-icon>'hummingbird-aviary' npm &amp; design system documentation (i.e. this documentation): <hb-link class="ml-2" color="secondary" href="http://sandbox.tenant-platform.com/design-system" target="_blank">http://sandbox.tenant-platform.com/design-system</hb-link></div>
                <div><hb-icon class="mr-3">mdi-magnify</hb-icon>Vuetify 2 documentation: <hb-link class="ml-2" color="secondary" href="https://v2.vuetifyjs.com/en/" target="_blank">https://v2.vuetifyjs.com/en/</hb-link></div>
                <div><hb-icon class="mr-3">mdi-magnify</hb-icon>Vee Validate (for form validations) documentation: <hb-link class="ml-2" color="secondary" href="https://vee-validate.logaretm.com/v3/guide/rules.html#rules" target="_blank">https://vee-validate.logaretm.com/v3/guide/rules.html#rules</hb-link></div>
                <div><hb-icon class="mr-3">mdi-magnify</hb-icon>Figma App where designers work on their source files: <hb-link class="ml-2" color="secondary" href="https://www.figma.com" target="_blank">https://www.figma.com</hb-link></div>
            </hb-form>

            <hb-form label="Dependencies" multiple>
                <div><hb-icon class="mr-3">mdi-cloud-download</hb-icon>"core-js": "^3.4.3",</div>
                <div><hb-icon class="mr-3">mdi-cloud-download</hb-icon>"vue": "^2.6.10",</div>
                <div><hb-icon class="mr-3">mdi-cloud-download</hb-icon>"vuetify": "2.3.23", <span class="font-weight-medium ml-1">(important: we must hardcode to this version)</span></div>
                <div><hb-icon class="mr-3">mdi-cloud-download</hb-icon>"material-design-icons-iconfont": "^5.0.1",</div>
                <div><hb-icon class="mr-3">mdi-cloud-download</hb-icon>"vee-validate": "^2.2.5", (add "import VeeValidate from 'vee-validate';" to main.js)</div>
                <div><hb-icon class="mr-3">mdi-cloud-download</hb-icon>"libphonenumber-js": "^1.9.6",</div>
                <div><hb-icon class="mr-3">mdi-cloud-download</hb-icon>Main font: "Graphik Web", Download here:<hb-link class="ml-1" color="secondary" href="https://drive.google.com/drive/folders/134VqCqMKsl91hwxC8X_yUG9-ju5Md6-D?usp=sharing" target="_blank">https://drive.google.com/drive/folders/134VqCqMKsl91hwxC8X_yUG9-ju5Md6-D?usp=sharing</hb-link></div>
                <div><hb-icon class="mr-3">mdi-cloud-download</hb-icon>Add required global CSS classes &amp; rules: <hb-link class="ml-1" :to="'/design-system/global-css'">Go to Global CSS Page</hb-link></div>
                <div><hb-icon class="mr-3">mdi-cloud-download</hb-icon>Import and register Aviary components: <hb-link class="ml-1" :to="'/design-system/component-registration'">Go to Component Registration Page</hb-link></div>
            </hb-form>

            <hb-form label="Expectations" multiple>
                <div><hb-icon class="mr-3">mdi-coffee</hb-icon>Hummingbird Aviary custom components are always used in all relevant places for future maintainability and visual uniformity.</div>
                <div><hb-icon class="mr-3">mdi-coffee</hb-icon>Developers become highly efficient and knowledgable with the Aviary documentation and Vuetify documentation.</div>
                <div><hb-icon class="mr-3">mdi-coffee</hb-icon>Developers adhere to design system documentation rules for the rest of things or when in doubt.</div>
                <div><hb-icon class="mr-3">mdi-coffee</hb-icon>Thoroughness, meticulousness, consistency of code use</div>
            </hb-form>

            <hb-form label="Do's" multiple>
                <div><hb-icon color="success" class="mr-3">mdi-check</hb-icon>Reach out to a designer or project mananger if you see an inconsistency with the design mockups/comps compared to the design system rules and documenation.</div>
                <div><hb-icon color="success" class="mr-3">mdi-check</hb-icon>Try to be as close to pixel perfect in matching design mockups/comps as humanly and reasonably possible.</div>
                <div><hb-icon color="success" class="mr-3">mdi-check</hb-icon>Give feedback to us if an Aviary component could be better or if there is something wrong with it.</div>
                <div><hb-icon color="success" class="mr-3">mdi-check</hb-icon>Communicate constantly with the design team before, during, and after building things.</div>
                <div><hb-icon color="success" class="mr-3">mdi-check</hb-icon>Have thorough QA. Give QA the time and training to fully understand the design system rules as well.</div>
                <div><hb-icon color="success" class="mr-3">mdi-check</hb-icon>Become an expert with using Vuetify. Consider using Vuetify helpers and wrappers instead of regular html/css/javascript whenever possible. This is because Vuetify has built in cross-browser compatibility and mobile page responsiveness that has been thoroughly tested, so it's better than using standard html/css/javascript. Vuetify is very powerful and useful and can greatly shorten your development time.</div>
                <div><hb-icon color="success" class="mr-3">mdi-check</hb-icon>Be sure to scope your styles in the vue files you create, otherwise they will override everything application wide. For example you should use "&lt;style scoped>&lt;/style>" instead of "&lt;style>&lt;/style>".</div>
            </hb-form>

            <hb-form label="Dont's" multiple>
                <div><hb-icon color="error" class="mr-3">mdi-close-circle</hb-icon>Do not edit any of the Aviary custom component files under any circumstances. Reach out to us if you need something changed or find a way to work within the limits of the existing component.</div>
                <div><hb-icon color="error" class="mr-3">mdi-close-circle</hb-icon>Don't go with "it looks good enough/close enough" mentality. The mentality should be "professional level quality".</div>
                <div><hb-icon color="error" class="mr-3">mdi-close-circle</hb-icon>Don't manually code things without looking to see if there is already something premade that can handle it. Reach out to others to see if something already exists.</div>
            </hb-form>

            <hb-form label="Changing Existing Aviary Components" multiple>
                <div><hb-icon color="caution" class="mr-3">mdi-alert</hb-icon>In the rare case that an Aviary component needs to be changed: Never delete or change any type of functionality to an existing Aviary component as something, somewhere may be currently using it. Only add functionality and be extra careful not to alter any existing functionality.</div>
                <div><hb-icon color="caution" class="mr-3">mdi-alert</hb-icon>Seek approval before changing the component and make an ADSY ticket if given the go ahead.</div>
            </hb-form>

            <hb-form label="New Aviary Components" multiple>
                <div><hb-icon color="caution" class="mr-3">mdi-alert</hb-icon>Must follow the same naming structure as other Aviary components. For example: "&lt;hb-component-name>" + "HbComponentName.vue" (i.e. all component vue file names should start with "Hb")</div>
                <div><hb-icon color="caution" class="mr-3">mdi-alert</hb-icon>Must be visual only in nature and functionality and not be application specific (i.e. no api calls from within the component, no vuex, no event buses). Data should be passed into the component from the parent component where the component is called.</div>
                <div><hb-icon color="caution" class="mr-3">mdi-alert</hb-icon>Should be made for things that are commonly used and not overly complex.</div>
                <div><hb-icon color="caution" class="mr-3">mdi-alert</hb-icon>Should be as vanilla yet flexible as possible so that any application can use it.</div>
                <div><hb-icon color="caution" class="mr-3">mdi-alert</hb-icon>Reach out to us if you feel there needs to be a new custom component built for the npm package that would help you or that you think would help everyone.</div>
                <div><hb-icon color="caution" class="mr-3">mdi-alert</hb-icon>We will analyze to see if it makes sense to add it and when/how to allocate time and resources to build it.</div>
                <div><hb-icon color="caution" class="mr-3">mdi-alert</hb-icon>Anything Aviary or design system related will be located in the "ADSY" board/project in Jira. Any change or new addition needs to be documented in an ADSY ticket.</div>
            </hb-form>

            <hb-form label="Communicate" multiple>
                <div><hb-icon class="mr-3">mdi-face-agent</hb-icon>Communication is key. We have to treat the design system as important, and we have to communicate constantly about anything and everything related to it.</div>
            </hb-form>

        </hb-card>
        <!--
        <hb-card title="Table of Contents" class="mt-4 mb-6">
            <v-list>
                <v-list-item v-for="(item, i) in menuItems" v-if="i > 0" :to="'/design-system' + item.path" class="pl-7" :key="item.name">
                    <hb-link>{{item.title}}</hb-link>
                </v-list-item>
            </v-list>        
        </hb-card>
        -->

    </div>
</template>
<script type="text/babel">

    import { EventBus } from '../../EventBus.js';

    export default {
        name: "DesignSystem",
        data: function() {
            return {
              selection: []
            };
        },
        props: [ 'menuItems'],
    }
</script>

<style scoped>

.design-system-overview-wrapper * {
    line-height: 27px !important;
}

</style>

<style>

.design-system-overview-wrapper .hb-aviary-form-slot-wrapper {
    margin: -5px 0 !important;
}

</style>

